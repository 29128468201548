.frames {
  opacity: 0;
}
.projectContainer {
  max-width: 1536px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  margin: 10rem 0;
}
.photoGrid {
  display: grid;
  width: 100%;
  grid-column-gap: 12px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
}
.videoGrid {
  display: grid;
  width: 100%;
  grid-column-gap: 12px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
}

.photoframe {
  color: #000;
  text-decoration: none;
  grid-area: span 1 / span 5 / span 1 / span 5;
}
.videoframe {
  grid-area: span 1 / span 10 / span 1 / span 10;
}
.content {
  margin-left: 10px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.year {
  font-size: 1.3rem;
  color: rgb(135, 135, 135);
}
.backgroundContainer {
  width: 100%;
  position: relative;
  border-radius: 10px !important;
  overflow: hidden;
  background-color: #f2f2f2;
}

.backgroundImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  background-color: #f2f2f2;
}
.backgroundVideo{
  background-color: #f2f2f2;
}
.frame:hover .overlay {
  opacity: 1;
}

.frame:hover .content {
  opacity: 1;
}

.heading {
  font-weight: 500;
  font-size: 1.8rem;
  text-align: left;
  line-height: auto;
  margin-bottom: 1rem;
  color: black;
}
.subhead {
  font-weight: 400;
  font-size: 1.3rem;
  text-align: left;
  color: rgb(135, 135, 135);
}
.projecttype {
  margin-top: 1rem;
  font-family: "Overpass Mono", sans-serif;
}
@media (max-width: 1024px) {
  .frame,
  .videoframe {
    grid-area: span 1 / span 12 / span 1 / span 12;
    margin-bottom: 1rem;
    gap: 0;
  }
  .photoframe {
    grid-area: span 1 / span 6 / span 1 / span 6;
    margin-bottom: 1rem;
    gap: 0;
  }
}
@media screen and (max-width: 767px) {
  .frame,
  .videoframe,
  .photoframe {
    grid-area: span 1 / span 12 / span 1 / span 12;
    margin-bottom: 1rem;
    gap: 0;
  }
  .photoGrid {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
  .content {
    margin-top: 1rem;
    grid-area: span 1 / span 12 / span 1 / span 12;
    flex-direction: column;
  }
  .projectContainer {
    gap: 5rem;
    padding: 0 3rem;
    margin: 5rem 0;
  }
}
