/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Large: 1.8

- Letter spacing
-0.5px
0.75px

--- 02 COLORS

- Primary:
- Tints:
- Shades: 
- Accents:
- Greys:

--- 05 SHADOWS

--- 06 BORDER-RADIUS

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Overpass+Mono");
@import url("https://fonts.googleapis.com/css?family=Bitter");

:root {
  --primary-color: #fff;
  --secondary-color: #d9d9d9;
  --third-color: #e8ca5d;
  --text-color: #222;
  --bg-color: #fff;
  --footer-bg-color: #ffffff;
  --border-color: rgba(255, 255, 255, 0.6);
  --input-color: #a3a3a3;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 16px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* @media screen and (max-width: 1440px) {
  html {
    font-size: calc(0.9063475546305931rem + 0.10405827263267431vw);
  }
}

@media screen and (max-width: 1920px) {
  html {
    font-size: calc(0.625rem + 0.41666666666666674vw);
  }
}
@media screen and (min-width: 1920px) {
  html{
    font-size:1.3rem;
  }
} */

body {
  font-family: "Inter", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: var(--text-color);
  background-color: var(--bg-color);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10rem;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.gap-3 {
  gap: 0.75rem; /* 12px */
}
.gap-5 {
  gap: 1.25rem; /* 20px */
}

.gridContainer {
  max-width: 1440px;
  width: 100%;
  display: grid;
  grid-column-gap: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
}
.description {
  grid-area: span 1 / span 10 / span 1 / span 10;
  padding: 10rem 0;
}
.description-header {
  font-size: 36px;
  line-height: 150%;
  font-weight: 400;
  text-align: left;
}

.projects {
  max-width: 106.4rem;
}
.navContainer {
  padding: 0 5rem;
}

.width--fill {
  width: 100%;
}

.footer > div {
  text-align: left;
}
.footer {
  margin-bottom: 3rem;
}

.footer a:link,
.footer a:visited {
  color: var(--text-color);
  text-decoration: none;
}
.italicLink a {
  color: #828282;
  font-style: italic;
}
.underlineLink a {
  text-decoration: noen;
  color: #222;
  border-bottom: 2px solid;
}
.infoContainer {
  max-width: 1500px;

  margin: 10rem 0 5rem;
}

.projectSection {
  padding: 0 0 10rem 0;
  display: flex;
  flex-direction: column;
  gap: 72px;
}
.introSection {
  padding-top: 2rem;
  padding-bottom: 10rem;
}

strong {
  font-weight: 500;
  color: black;
}
.info2Columns {
  display: flex;
  gap: 20px;
  flex: 1;
}
.info2Columns > div {
  width: 50%;
}

.fadeSection,
.introSection,
.hero,
header,
.description,
.infoContainer,
.frames {
  opacity: 0;
}

.hero {
  color: var(--bg-color);
  display: flex;
  justify-content: center;
}
.hero img {
  max-height: 100%;
  max-width: 100%;
}

.logo {
  width: 3rem;
}

img:not(.logo) {
  width: 100%;
  height: auto;
  display: block;
}
img,
video {
  /* opacity: 0; */
  /* transition: opacity 0.3s ease-in-out; */
}

img.loaded,
video.loaded {
  opacity: 1;
}

/* Define the fade-in animation */
.fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

/* Keyframes for the fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.scrolled {
  /* Styles when the navbar is scrolled */
  background-color: #333; /* Change to your desired background color */
  height: 60px; /* Change to your desired height */
}

.hidden {
  transform: translateY(-100%) !important;
}
.homeDivider {
  width: 100%;
  height: 1px;
  background-color: #c6c6c6;
  grid-area: span 1 / span 12 / span 1 / span 12;
  display: block; /* Ensure it behaves like a block element */
}
.pill {
  grid-area: span 1 / span 2 / span 1 / span 2;
  margin-top: 10px;
}

.divBlock1 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.divBlock2 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}
.divBlock3 {
  grid-area: span 1 / span 3 / span 1 / span 3;
}
.divBlock4 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}
.divBlock5 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}
.divBlock6 {
  grid-area: span 1 / span 6 / span 1 / span 6;
}
.divBlock7 {
  grid-area: span 1 / span 7 / span 1 / span 7;
}
.divBlock8 {
  grid-area: span 1 / span 8 / span 1 / span 8;
}
.divBlock9 {
  grid-area: span 1 / span 9 / span 1 / span 9;
}
.divBlock10 {
  grid-area: span 1 / span 10 / span 1 / span 10;
}
.divBlock11 {
  grid-area: span 1 / span 11 / span 1 / span 11;
}
.divBlock12 {
  grid-area: span 1 / span 12 / span 1 / span 12;
}
@media (max-width: 1024px) {
  .description {
    grid-area: span 1 / span 12 / span 1 / span 12;
    padding: 1rem 0;
    margin: 10rem 0;
  }
  .divBlock2 {
    display: none;
  }
  .pill {
    grid-area: span 1 / span 5 / span 1 / span 5;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .description {
    grid-area: span 1 / span 12 / span 1 / span 12;
    padding: 1rem 0;
    margin: 5rem 0;
  }
  .description-header {
    font-size: 26px;
  }
  .infoContainer {
    margin: 2rem 0 5rem;
  }
  .gridContainer {
    padding: 0 3rem;
  }
  .navContainer {
    padding: 0 3rem;
  }
}
.infoPageContainer {
  gap: 80px;
}
.link {
}
