.caseStudyParagraph {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

.divBlock1 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.divBlock2 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}
.divBlock3 {
  grid-area: span 1 / span 3 / span 1 / span 3;
}
.divBlock4 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}
.divBlock5 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}
.divBlock6 {
  grid-area: span 1 / span 6 / span 1 / span 6;
}
.divBlock7 {
  grid-area: span 1 / span 7 / span 1 / span 7;
}
.divBlock8 {
  grid-area: span 1 / span 8 / span 1 / span 8;
}
.divBlock9 {
  grid-area: span 1 / span 9 / span 1 / span 9;
}
.divBlock10 {
  grid-area: span 1 / span 10 / span 1 / span 10;
}
.aboutMe {
  grid-area: span 1 / span 6 / span 1 / span 6;
}

.introTitleImageContainer {
  margin: 2rem 0 5rem;
}
.introItems {
  grid-area: span 1 / span 3 / span 1 / span 3;
}
.introItems h2 {
  margin-bottom: 2rem;
}

.keyResultBlock {
  grid-area: span 1 / span 6 / span 1 / span 6;
  display: flex;
  flex-direction: column;
}
.keyContentBlock {
  display: flex;
  justify-content: space-between;
}
.introBlurb {
  grid-area: span 1 / span 8 / span 1 / span 8;
  margin-bottom: 5rem;
}

.gridContainer {
  max-width: 1440px;
  display: grid;
  grid-column-gap: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  width: 100%;
}
.section4-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: fit-content;
}
.infoParagraph {
  grid-area: span 1 / span 5 / span 1 / span 5;
}
.infoHeading {
  grid-area: span 1 / span 5 / span 1 / span 5;
}
.infoHeading h1 {
  line-height: 4rem;
}
.annotation {
  background-color: #f2f2f2;
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
}
.imageAnnotationContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.section4 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slice--grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: top;
  gap: 2rem;
}

.timeline {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.images-2-blocks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.resized-image {
  max-width: 20rem;
  margin-bottom: 1rem;
}

.boxes {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6rem;
  color: var(--primary-color);
}

.box {
  background-color: var(--footer-bg-color);
  width: 96rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
  gap: 3rem;
  border-radius: 3rem;
}

.box > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  list-style-type: none;
}

.list > li {
  margin: 1.5rem 0;
}
.list > li:first-child {
  margin-top: 0px;
}

.sub-bodys {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.copy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.questions > li {
  margin-left: 1.5rem;
}

.sideBar {
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 120px; /* Stay at the top */
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
}
.sideBar h2 {
  font-size: 1rem;
  margin-left: 16px;
  margin-bottom: 1rem;
}

.sidebarLink {
  padding: 8px 16px;
  border-radius: 20px;
  width: 180px;
  display: flex;
  align-items: flex-start;
}
.sidebarLink:hover {
  cursor: pointer;
  transition: color 0.5s ease;
}
/* .sideBar a :hover {

  color: rgb(75, 75, 75);
  transition: color 0.5s ease;
} */
.activeLink {
  background-color: rgb(247, 247, 247);
}
.activeLink p {
  font-weight: 600;
  color: #000 !important;
  transition: 0.2s ease-out; /* Adjust the duration and easing as needed */
}
.backButton {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 50px 10px;
}
.insightContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.iconContentContainer {
  background-color: #fbfbfb;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  padding:16px 20px;
}

.insightItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align bullet to the top of the container */
  gap: 30px;
  width: 90%;
  background-color: #fbfbfb;
  /* padding: 16px 20px; */
  border-radius: 15px;
}
.bullet {
  flex-shrink: 0; /* Prevent the bullet from shrinking */
  width: 1rem; /* Set a fixed width for the bullet */
  height: 1rem; /* Set a fixed height for the bullet */
  margin-right: 0.5rem; /* Add spacing between bullet and paragraph */
}
.sectionDivider {
  width: 100%;
  height: 1px;
  background-color: #c6c6c6;
  display: block; /* Ensure it behaves like a block element */
}
.animate-menu {
  transition: transform 0.5s ease-in-out; /* Add more animation properties as needed */
  transform: translateY(/* your animation distance, e.g., -100% */);
}

.imageContainer {
  border-radius: 10px; /* Adjust the value to control the amount of rounding */
  overflow: hidden; /* Ensure the video stays within the rounded container */
  border: 0.5px solid;
  color: #c4c4c4;
  background-color: #f8f8f8;
  min-height: 20rem;
}

video {
  width: 100%;
  height: 100%;
}
.experienceItems {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

/* Accordion item */
.accordionItem {
  border-top: 1px solid;
}

/* Accordion item header */
.accordionHeader {
  padding: 20px;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Accordion item content */
.accordionContent {
  padding: 20px;
}

.accordionContent ul {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.accordion {
  border-bottom: 1px solid;
}
.jobDate {
  margin-bottom: 3rem;
}

.keyResultBlock {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

@media (max-width: 1024px) {
  .sideBar,
  .divBlock2 {
    display: none;
  }
  .divBlock6 {
    grid-area: span 1 / span 8 / span 1 / span 8;
  }
  .divBlock10 {
    grid-area: span 1 / span 12 / span 1 / span 12;
  }
  .divBlock5 {
    grid-area: span 1 / span 6 / span 1 / span 6;
  }
}
@media (max-width: 767px) {
  .divBlock,
  .divBlock1,
  .divBlock2,
  .sideBar {
    display: none;
  }

  .divBlock3,
  .divBlock4,
  .divBlock5,
  .divBlock6,
  .divBlock10,
  .fullWidth,
  .accordion,
  .insightContainer,
  .IntroH1,
  .infoParagraph,
  .introBlurb,
  .caseStudyParagraph,
  .contextBlock,
  .aboutMe,
  .paragraphContainer,
  .infoHeading {
    grid-area: span 1 / span 12 / span 1 / span 12;
  }

  .keyResultBlock {
    grid-area: span 1 / span 4 / span 1 / span 4;
  }

  .infoHeading {
    margin-bottom: 2rem;
  }
  .paragraphTitle {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
  .paragraphSubtitle1 {
    grid-area: span 1 / span 10 / span 1 / span 10 !important;
  }
  .introItems {
    grid-area: span 1 / span 6 / span 1 / span 6;
  }

  .jobDate {
    margin-bottom: 1.8rem;
  }

  .gridContainer {
    padding: 0 3rem;
  }

  .imageAnnotationContainer {
    order: 2;
  }
  .paragraphContainer {
    order: 1;
    margin-bottom: 2rem;
  }
}
.pill {
  margin-top: 16px;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 6px 20px;
  width: fit-content;
}
.pill p {
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;

}
.quotes {
  margin-top: 12px;
  padding: 8px 20px;
  border-left: 5px solid #fcb11d;
}
.quotes p {
  font-style: italic;
}
