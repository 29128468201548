.input {
	display: block;
}

.input {
	flex: 3;
	font: inherit;
	font-size: 1.4rem;
	line-height: 1.5;
	width: 30rem;
	height: 5rem;
	padding-left: 2rem;
	border: 1px solid var(--border-color);
	background-color: var(--bg-color);
	color: var(--input-color);
}

.input:focus {
	outline: none;
}
