.footerMainContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5rem 2rem;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}
.footer p {
  line-height: 1;
  display: flex;
  align-items: center;
}
.mainFooterContent {
  display: flex;
  align-items: center;
}
.footerLinkMobile {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mobileLeftContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.copyrightContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footerItemLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}
.footerItemRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3rem;
}
.link {
  display: inline-block;
  position: relative;
  font-weight: 500;
  color: #000;
}
.link:first-child {
  margin: 0 3rem 0 0;
}
/* .link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #222222;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}
.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
} */

.linkNext {
  position: relative;
  right: 0;
  margin: 2rem 5rem;
  text-align: right;
}
a {
  text-decoration: none;
}

.linkNextContainer {
  margin: 2rem;
}

.footerAboutMainContent {
  display: flex;
  justify-content: left;
  margin: 0 20px 50px 20px;
}
@media screen and (max-width: 767px) {
  .footerMainContent {
    align-items: flex-start;
    margin: 0 3rem 2rem;
  }

  .footerSubtitle {
    margin-top: 1rem;
  }
  .link,
  .link:first-child {
    margin: 0.5rem 0 0 0;
  }
  .footerItem {
    gap: 1rem;
    align-items: flex-start;
  }
}
