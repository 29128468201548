.login {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	max-width: 60rem;
	margin: 0 auto;
	padding: 3rem;
	color: var(--text-color);
}

.login img {
	display: block;
	margin: 0 auto 1.5rem;
	width: 5.3rem;
	height: auto;
}

.login h3 {
	display: block;
	text-align: center;
}

.login p {
	text-align: center;
}

.email {
	margin: 0.5rem auto 3.5rem;
}

.invalid {
	margin: 1rem auto;
}

.login a:link,
.login a:visited {
	color: var(--text-color);
	text-decoration: none;
}

.input {
	margin: 1rem auto;
}

.actions {
	text-align: center;
}

.btn {
	font: inherit;
	border: 1px solid var(--third-color);
	background: var(--third-color);
	color: var(--primary-color);
	padding: 1.5rem 12rem;
	cursor: pointer;
	font-size: 1.15rem;
	border-radius: 30px;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
	background: #ccc;
	border-color: #ccc;
	color: #666666;
	cursor: not-allowed;
}
