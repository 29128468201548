.displayLg {
  font-size: 3.5rem;
  font-family: "Bitter", serif;
}
.displayMd {
  font-size: 2.8rem;
  font-family: "Bitter", serif;
}
.displaySm {
  font-size: 2.25rem;
  font-family: "Bitter", serif;
}
h1,
h2,
h3,
h5,
h4,
h6 {
  font-family: "Inter", sans-serif;
}
h1 {
  font-size: 2rem;
  line-height: 1.25;
  font-weight: 500;
}

h2 {
  font-size: 1.75rem;
  line-height: 1.5;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
}
/* h4 {
  font-size: 1.8rem;
  line-height: 1.8;
  font-weight: 500;
} */

p,
ol,
ul {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 400;
  color: #5c5c5c;
}

.titleLg {
  font-family: "Inter", sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
}
.titleMd {
  font-size: 1.2rem;
  font-weight: 500;
}
.titleSm {
  font-size: 1rem;
  font-weight: 500;
}
.label {
  font-size: 0.875rem;
}
.bodySm {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}
.overline {
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

.copy {
  font-size: 3.2rem;
  line-height: 1.6;
  font-weight: 400;
}

.copy2 {
  font-size: 2rem;
  line-height: 1.5;
}

.annotation {
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 400;
}

.center {
  text-align: center;
  font-size: 2rem;
}

.main {
  min-height: 80vh;
}
.flex {
  display: flex;
}
.info-heading {
  margin: 16rem 0 5rem;
}

.paragraph-margin {
  margin-bottom: 2rem;
}
.mb-5 {
  margin-bottom: 0.5rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.mb-20 {
  margin-bottom: 2rem;
}
.mb-30 {
  margin-bottom: 3rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-50 {
  margin-bottom: 5rem;
}

.mb-70 {
  margin-bottom: 7rem;
}

.mb-80 {
  margin-bottom: 8rem;
}

.mb-100 {
  margin-bottom: 10rem;
}

.mb-150 {
  margin-bottom: 15rem;
}

.mb-200 {
  margin-bottom: 20rem;
}

.mb-250 {
  margin-bottom: 25rem;
}

.tp-10 {
  margin-top: 1rem;
}
.tp-20 {
  margin-top: 2rem;
}

.tp-30 {
  margin-top: 3rem;
}

.tp-50 {
  margin-top: 5rem;
}

.tp-80 {
  margin-top: 8rem;
}

.tp-100 {
  margin-top: 10rem;
}

.tp-200 {
  margin-top: 20rem;
}

.tp-600 {
  margin-top: 70rem;
}
.tp-800 {
  margin-top: 80rem;
}

.lrb-50 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.lrb-70 {
  margin-left: 7rem;
  margin-right: 7rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pb-10 {
  padding-bottom: 2.5rem; /* 40px */
}
.pb-20 {
  padding-bottom: 5rem; /* 80px */
}
.px-20 {
  padding-left: 5rem; /* 80px */
  padding-right: 5rem; /* 80px */
}
.py-10 {
  padding-top: 2.5rem; /* 40px */
  padding-bottom: 2.5rem; /* 40px */
}
.py-20 {
  padding-top: 5rem; /* 80px */
  padding-bottom: 5rem; /* 80px */
}
.py-100 {
  padding: 10rem 0;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-20 {
  gap: 5rem;
}
.pill p {
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.content-block {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.highlight {
  background-color: #ffd88c;
  padding: 0 5px;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.8rem;
  }
  h3 {
    font-size: 2.3 rem;
    margin-bottom: 1.2rem;
  }
  h4 {
    font-size: 1.6rem;
  }
  p {
    font-size: 1.6rem;
  }
  .divBlock2 {
    display: none;
  }
  .mb-50 {
    margin-bottom: 3rem;
  }
  .pill {
    grid-area: span 1 / span 5 / span 1 / span 5;
    margin-bottom: 3rem;
  }
}

.imageContainer {
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  border: 0.5px solid;
  color: #c4c4c4;
  background-color: #f8f8f8;
  min-height: 10rem;
}
