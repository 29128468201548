.header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  transition: transform 0.3s, background-color 0.3s;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}
.header .hidden {
  transform: translateY(-100%);
}
.headerProject {
  background-color: #f2f2f2;
}
.headerProject > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
}

.main-nav > ul {
  list-style: none;
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;

  text-transform: uppercase;
  color: var(--text-color);
  padding: 5px 10px;
  border-radius: 20px;
}

/* Define a transition for link color and background color */
.main-nav-link {
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Define a transition for link color and background color */
.main-nav-link {
  transition: color 0.3s, background-color 0.5s;
  /* Add your default link styles here */
}

/* Add styles for the active link */
.main-nav-link.active {
  color: #ffffff; /* Change to your desired active link color */
  background-color: #222; /* Change to your desired active background color */
}

/* Add hover styles to change link color and background on hover */
.main-nav-link:hover {
  color: #ffffff; /* Change to your desired hover link color */
  background-color: #333; /* Change to your desired hover background color */
}
