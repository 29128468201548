.about {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 3rem;
  margin-bottom: 20rem;
}

.infoContainer {
  margin: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 100px;
  opacity: 0;
}
.experienceTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

ul {
  list-style: none;
}
.experienceContainer li {
  position: relative;
  padding: 10px 10px 30px 10px;
}

.experienceContainer li:before {
  background: url(../../images/icons/bullet.svg) no-repeat left top;
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  left: -30px;
  top: 24px;
  transform: translateY(-45%);
}

.experienceContainer li:not(:last-child):after {
  content: "";
  width: 2px;
  height: 80%;
  background: #868686;
  position: absolute;
  left: -21px;
  top: 33px;
}
.experienceItemContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 40px;
}

.timeText {
  width: 180px;
}
.grayContainer {
  background-color: aliceblue;
  border-radius: 20px;
  padding: 35px 40px;
  margin: 10px 0px;
}
.sticky {
  position: absolute;
  top: 0;
  z-index: 1; /* Stay on top */
}
.contactContainer {
  width: 250px;
}
.contactListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contactItem {
  display: flex;
  gap: 10px;
  align-items: center;
}
.softwareContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}
.technicalContainer {
  display: flex;
  flex-direction: row;
  gap: 100px;
}
li {
  list-style-type: none; /* Remove bullets */
}
.codeContainer {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.imageVisible {
  opacity: 1;
}
.gallary {

  display: grid;
  grid-column-gap: 30px;
  grid-template-rows: repeat(3, 1fr); /* Adjust the height values as needed */
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: row;
  padding: 5rem 5rem;
}
